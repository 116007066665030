import { useState, useEffect } from 'react';
import { Paper, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { successHandler, errorHandler } from '@/utils/toastHandler';

import { Box, Flex, FormBox } from '@/layout';
import { InputWrapper, MultiInputWrapper, FormTitle } from '@/components/Container/AuthContainer/styles';
import { FormSelect } from '@/components/UI/Select';
import { H4, H5 } from '@/components/UI/Typography/Heading';
import { ErrorMessage } from '@/components/UI/Typography/Text';
import ImageDefault from '@/components/UI/Input/ImageDefault';
import { LoadingPage } from '@/layout';

import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, update } from '@/redux/auth/auth.slice';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import useRegistrationValidation from '@/hooks/requireSchema/useRegistrationValidation';
import useCountryDetail from '@/hooks/useCountryDetail';
import {
  convertLink,
  convertToSelectData,
  convertCountryCode,
  converToMultiLangSelectData,
  convertCNRegionData,
  convertCNCitiesData,
} from '@/utils/convert';
import { getTempData, removeTempData } from '@/utils/localStorageSave';
import { registrationCompany } from '@/api/auth.api';

import MultiSelect from '@/components/UI/Select/MultiSelect';

import { category, country, categoryLang } from '@/data/select';
import { useDispatch } from 'react-redux';
import { fileSizeValidator } from '@/utils/fileSizeValidator';
import useCurrentLang from '@/hooks/useCurrentLang';
import { cnRegion, specialRegion } from '@/data/china-region';
import useResize from '@/hooks/useResize';

const CompanyInfo = ({
  nextHandler = () => {},
  ButtonGroup,
  ReturnLink = '',
  userDetail,
  readOnly = false,
}: ProfileProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    resetField,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<RegistrationCompanyInfoForm>();
  const { isMobile, isTablet } = useResize();
  const auth = useAppSelector(getAuthSelector);
  const lang = useCurrentLang();
  const dispatch = useDispatch();
  // currencyData, languages
  const currencyData = useCountryDetail('currency');
  const languages = useCountryDetail('languages');

  const { countryCodeRegister } = useRegistrationValidation().companyInfo();
  const { inputRegister, emailRegister } = useAuthValidation();
  const [isSub, setIsSub] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showProvinceControl, setShowProvinceControl] = useState<boolean>(false);
  const [showLegalService, setShowLegalService] = useState<boolean>(false);

  const [stopInit, setStopInit] = useState<boolean>(false);
  const sectorOptions = t('mapOptions.lawFirmSectorSpecialty', { returnObjects: true });
  const areasOptions = t('mapOptions.practiceAreas', { returnObjects: true });

  const convertCountryName = (countryCode: string) => {
    const currentLang = lang === 'en' ? 'en' : lang === 'sc' ? 'sc' : 'tc';
    // 找出該名稱在country['en']中的index值
    const correctCountry = country[currentLang].find((item) => item.countryCode === countryCode);
    return correctCountry ? correctCountry.name : '';
  };

  useEffect(() => {
    if (!userDetail) return;
    setValue('address_country', convertCountryName(userDetail.acc_countryCode));
  }, [lang]);

  // 監聽acc_countryCode的變化去更新address_country的值
  const watchCountryCode = watch('acc_countryCode');
  useEffect(() => {
    if (!userDetail) return;
    if (!watchCountryCode) return;

    // 將國碼取出
    const code = watchCountryCode.split(' ')[0];
    // 將countryName設定到address_country
    if (code) {
      setValue('address_country', convertCountryName(code));
    } else {
      setValue('address_country', '');
    }

    addressRequiredHandler(code);

    if (code !== userDetail.acc_countryCode) {
      // 重置address和province和city的值
      setValue('province', '');
      setValue('city', '');

      // 檢測若為香港, 澳門, 台灣則將province和city設為對應的資料
      if (code === '+852' || code === '+853' || code === '+886') {
        let province = '';
        let city = '';
        if (code === '+852') {
          province = cnRegion[1].province_zh;
          city = cnRegion[1].cities[0].city_zh;
        } else if (code === '+853') {
          province = cnRegion[2].province_zh;
          city = cnRegion[2].cities[0].city_zh;
        } else if (code === '+886') {
          province = cnRegion[3].province_zh;
          city = cnRegion[3].cities[0].city_zh;
        }
        setValue('province', province);
        setValue('city', city);
      } else if (code === '+86') {
        setValue('address_floor', '');
        setValue('address_street', '');
        setValue('address_district', '');
        setValue('province', cnRegion[0].province_zh);
        setValue('city', cnRegion[0].cities[0].city_zh);
      }
    }
  }, [watchCountryCode]);

  // 監聽並限制logo檔案大小
  const watchLogo = watch('acc_logo');
  useEffect(() => {
    if (watchLogo && watchLogo.length > 0 && !fileSizeValidator(watchLogo[0], 2)) {
      resetField('acc_logo');
      errorHandler(t('error.logoSize'));
    }
  }, [watchLogo]);

  const onSubmit = handleSubmit(async (data) => {
    if (isSub) return;
    // 檢查acc_catagory是否有數字 若有則對應到category陣列中的值並回填
    const acc_category = data.acc_category.map((item) => {
      if (Number(item)) {
        return category[Number(item)];
      } else {
        return item;
      }
    });

    // 移除陣列中是否有undefined或空的字串
    const acc_languages = data.acc_languages.filter((item) => item !== 'undefined' && item !== '');

    // 檢查若國碼為中國則將省分與城市和地址合併
    let address = '';
    if (data.acc_countryCode.split(' ')[0] === '+86') {
      if (specialRegion.includes(data.province)) {
        address = data.province + '@@_@@' + data.address_floor;
      } else {
        address = data.province + '@@_@@' + data.city + '@@_@@' + data.address_floor;
      }
    } else {
      address = data.address_floor + '@@_@@' + data.address_street + '@@_@@' + data.address_district;
    }

    const reqData: RegistrationCompanyRequest = {
      ...data,
      acc_logo: data.acc_logo?.[0],
      acc_address: address,
      acc_category: acc_category.join(', '),
      acc_languages: acc_languages.join(', '),
      acc_currency: data.acc_currency.join(', '),
      acc_countryCode: data.acc_countryCode.split(' ')[0],
      law_firm_sector_specialty:
        data.acc_category && data.acc_category.includes('Legal Services') ? data.law_firm_sector_specialty : [],
      practice_areas: data.acc_category && data.acc_category.includes('Legal Services') ? data.practice_areas : [],
    };

    try {
      setIsLoading(true);
      const res = (await registrationCompany(reqData)).data;
      if (res.success) {
        // 移除暫存資料
        removeTempData();
        // 註冊或更新成功後將資料更新至個人資料中
        const patch = await dispatch(update({ ...auth, userData: res.data }));

        nextHandler();
        successHandler(t('success.update'));
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error.response.data.data);
    }
  });

  useEffect(() => {
    if (stopInit) return;

    const userData = getTempData();
    if (userData) {
      setValue('acc_desc', userData.acc_desc ? userData.acc_desc : '');
      setValue('acc_coname', userData.acc_coname ? userData.acc_coname : '');
      setValue('acc_website', userData.acc_website ? userData.acc_website : '');
      setValue('com_email', userData.com_email ? userData.com_email : '');
      setValue('acc_countryCode', userData.acc_countryCode ? userData.acc_countryCode : '');
      setValue('acc_tel', userData.acc_tel ? userData.acc_tel : '');
      setValue('acc_fax', userData.acc_fax ? userData.acc_fax : '');
      setValue('address_country', userData.acc_countryCode ? userData.acc_countryCode : '');
      setValue('acc_languages_other', userData.acc_languages_other ? userData.acc_languages_other : '');
      setValue('acc_currency_other', userData.acc_currency_other ? userData.acc_currency_other : '');
      setValue('acc_category_other', userData.acc_category_other ? userData.acc_category_other : '');
      setValue('province', userData.province ? userData.province : '');
      setValue('city', userData.city ? userData.city : '');
      setValue(
        'law_firm_sector_specialty',
        userData.law_firm_sector_specialty && typeof userData.law_firm_sector_specialty === 'object'
          ? userData.law_firm_sector_specialty
          : []
      );
      setValue(
        'practice_areas',
        userData.practice_areas && userData.practice_areas === 'object' ? userData.practice_areas : []
      );
      setValue('acc_category', userData.acc_category ? userData.acc_category : []);
      setValue('acc_languages', userData.acc_languages ? userData.acc_languages : []);
      setValue('acc_currency', userData.acc_currency ? userData.acc_currency : []);
      setValue('acc_languages_other', userData.acc_languages_other ? userData.acc_languages_other : '');
      setValue('acc_currency_other', userData.acc_currency_other ? userData.acc_currency_other : '');
      setValue('acc_category_other', userData.acc_category_other ? userData.acc_category_other : '');
      addressRequiredHandler(userData.acc_countryCode);
      setValue('address_floor', userData.address_floor ?? '');
      setValue('address_street', userData.address_street ?? '');
      setValue('address_district', userData.address_district ?? '');
      return;
    }
    if (!userDetail) return;
    setIsSub(!userDetail.main_account_company_info.isMainAccount);
    setValue('acc_desc', userDetail.acc_desc ? userDetail.acc_desc : '');
    setValue('acc_coname', userDetail.acc_coname ? userDetail.acc_coname : '');
    setValue('acc_website', userDetail.acc_website ? userDetail.acc_website : '');
    setValue('com_email', userDetail.com_email ? userDetail.com_email : '');
    setValue('acc_countryCode', convertCountryCode(userDetail.acc_countryCode ? userDetail.acc_countryCode : ''));
    setValue('acc_tel', userDetail.acc_tel ? userDetail.acc_tel : '');
    setValue('acc_fax', userDetail.acc_fax ? userDetail.acc_fax : '');
    setValue('address_country', convertCountryName(userDetail.acc_countryCode ? userDetail.acc_countryCode : ''));
    setValue('acc_languages_other', userDetail.acc_languages_other ? userDetail.acc_languages_other : '');
    setValue('acc_currency_other', userDetail.acc_currency_other ? userDetail.acc_currency_other : '');
    setValue('acc_category_other', userDetail.acc_category_other ? userDetail.acc_category_other : '');
    setValue('province', userDetail.province ? userDetail.province : '');
    setValue('city', userDetail.city ? userDetail.city : '');
    setValue(
      'law_firm_sector_specialty',
      userDetail.law_firm_sector_specialty && typeof userDetail.law_firm_sector_specialty === 'object'
        ? userDetail.law_firm_sector_specialty
        : []
    );
    setValue(
      'practice_areas',
      userDetail.practice_areas && typeof userDetail.practice_areas === 'object' ? userDetail.practice_areas : []
    );

    addressRequiredHandler(userDetail.acc_countryCode);

    if (userDetail.acc_address) {
      // 將地址透過"@@_@@"分割成陣列進行回填
      const addressArray = userDetail.acc_address.split('@@_@@');

      if (addressArray.length >= 3 && userDetail.acc_countryCode !== '+86') {
        setValue('address_floor', addressArray[0]);
        setValue('address_street', addressArray[1]);
        setValue('address_district', addressArray[2]);
      } else if (addressArray.length === 2 && specialRegion.includes(addressArray[0])) {
        setValue('address_floor', addressArray[1]);
      } else if (!specialRegion.includes(addressArray[0])) {
        if (addressArray[2]) {
          setValue('address_floor', addressArray[2]);
        } else {
          setValue('address_floor', addressArray[1]);
        }
      }
    }

    if (userDetail.acc_category) {
      // 檢查是否有數字 若有則對應到category陣列中的值並回填
      const categoryArray = userDetail.acc_category.split(', ');
      categoryArray.forEach((item, index) => {
        if (Number(item)) {
          categoryArray[index] = category[Number(item)];
        }
      });

      setValue('acc_category', categoryArray);
    } else {
      setValue('acc_category', []);
    }

    if (userDetail.acc_languages) {
      // 檢查若有空字串或undfined字串則移除
      const languageArray = userDetail.acc_languages.split(', ');
      const filterLanguageArray = languageArray.filter((item) => item !== 'undefined' && item !== '');
      setValue('acc_languages', filterLanguageArray);
    } else {
      setValue('acc_languages', []);
    }

    if (userDetail.acc_currency) {
      // 將所有貨幣透過", "分割成陣列後, 再透過split(' ')將選項的所有單字首字母大寫後回填
      const currencyArray = userDetail.acc_currency.split(', ');
      currencyArray.forEach((item, index) => {
        const splitItem = item.split(' ');
        splitItem.forEach((item, index) => {
          splitItem[index] = item.charAt(0).toUpperCase() + item.slice(1);
        });
        currencyArray[index] = splitItem.join(' ');
      });

      setValue('acc_currency', currencyArray);
    } else {
      setValue('acc_currency', []);
    }

    setStopInit(true);
  }, [userDetail]);

  // // 監視acc_language的變化, 若使用者有選擇"other"則將acc_language_other顯示出來並加入驗證
  const watchAccLanguage = watch('acc_languages');
  useEffect(() => {
    if (watchAccLanguage && watchAccLanguage.includes('Other')) {
      register('acc_languages_other', { required: t('required.feild') });
    }
  }, [watchAccLanguage]);

  const watchCategory = watch('acc_category');
  useEffect(() => {
    if (watchCategory && watchCategory.includes('Other')) {
      register('acc_category_other', { required: t('required.feild') });
    }
    if (watchCategory && watchCategory.includes('Legal Services')) {
      setShowLegalService(true);
    } else {
      setShowLegalService(false);
    }
  }, [watchCategory]);

  const watchCurrency = watch('acc_currency');
  useEffect(() => {
    if (watchCurrency && watchCurrency.includes('Other')) {
      register('acc_currency_other', { required: t('required.feild') });
    }
  }, [watchCurrency]);

  const addressRequiredHandler = (code: string | null) => {
    if (!code) return;
    if (code === '+86' || code === '+86 (China)') {
      setShowProvinceControl(true);
    } else {
      setShowProvinceControl(false);
    }
  };

  return (
    <>
      <LoadingPage isLoading={isLoading} />
      <FormBox onSubmit={onSubmit} isTablet={isTablet}>
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'acc_coname'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                InputProps={{
                  readOnly: true,
                }}
                disabled={isSub}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />
        </InputWrapper>
        <Box>
          <input
            disabled={isSub || readOnly}
            accept="image/*"
            id="icon-button-file"
            type="file"
            {...register('acc_logo')}
            hidden
          />
          <label
            htmlFor={isSub ? '' : readOnly ? '' : 'icon-button-file'}
            style={{ display: 'flex', width: 'fit-content', cursor: 'pointer' }}
          >
            <Box marginRight={'20px'}>
              <H4>{t('form.companyLogo.label')}</H4>
              <H5>JPG、GIF、PNG</H5>
              <H5 color={'red'}>{t('form.companyLogo.placeholder')} 2MB</H5>
            </Box>
            <Flex flexDirection={'column'} gridGap={'10px'}>
              {userDetail && userDetail.acc_logo && !watch('acc_logo')?.[0] ? (
                <img height={100} src={userDetail.acc_logo} alt={'acc_logo'} />
              ) : watch('acc_logo')?.[0] ? (
                <img height={100} src={URL.createObjectURL(watch('acc_logo')?.[0] as Blob)} alt={'acc_logo'} />
              ) : (
                <img height={100} src={'/images/default_company_logo.jpg'} alt={'acc_logo'} />
              )}
              <ErrorMessage marginBottom={20}>{errors.acc_logo?.message}</ErrorMessage>
            </Flex>
          </label>
        </Box>
        <InputWrapper>
          <Controller
            control={control}
            name={'acc_website'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyWebsite.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                inputProps={{
                  readOnly: isSub || readOnly,
                }}
              />
            )}
          />
        </InputWrapper>

        <InputWrapper>
          <Controller
            control={control}
            name={'com_email'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyEmail.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                inputProps={{
                  readOnly: isSub || readOnly,
                }}
              />
            )}
          />
        </InputWrapper>

        <MultiInputWrapper isMobile={isMobile}>
          <FormSelect
            control={control}
            data={useCountryDetail('countryCode')}
            inputLabel={t('form.countryCode.label')}
            selectLabel={'Country Code'}
            name={'acc_countryCode'}
            disabled={true}
          />

          <Controller
            control={control}
            name={'acc_tel'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyTel.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                inputProps={{
                  readOnly: isSub || readOnly,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={'acc_fax'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyFax.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                inputProps={{
                  readOnly: isSub || readOnly,
                }}
              />
            )}
          />
        </MultiInputWrapper>

        <InputWrapper>
          <Box>
            <H4>{t('form.companyAddress.label')}</H4>
          </Box>
          <div style={{ width: 'calc(100% - 40px)', margin: 'auto' }}>
            {userDetail && showProvinceControl && (
              <>
                <FormSelect
                  control={control}
                  data={convertCNRegionData(cnRegion, lang)}
                  inputLabel={t('form.province.label')}
                  selectLabel={t('form.province.label')}
                  name={'province'}
                  readOnly={isSub || readOnly}
                />
                <FormSelect
                  control={control}
                  data={convertCNCitiesData(cnRegion, lang, watch('province'))}
                  inputLabel={t('form.city.label')}
                  selectLabel={t('form.city.label')}
                  name={'city'}
                  readOnly={isSub || readOnly}
                />
              </>
            )}
            <Controller
              control={control}
              name={'address_floor'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('form.companyAddress.floor')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                  inputProps={{
                    readOnly: isSub || readOnly,
                  }}
                />
              )}
            />
            {userDetail && !showProvinceControl && (
              <>
                <Controller
                  control={control}
                  name={'address_street'}
                  defaultValue={''}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label={t('form.companyAddress.street')}
                      variant="standard"
                      value={value}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete="off"
                      inputProps={{
                        readOnly: isSub || readOnly,
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={'address_district'}
                  defaultValue={''}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label={t('form.companyAddress.district')}
                      variant="standard"
                      value={value}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete="off"
                      inputProps={{
                        readOnly: isSub || readOnly,
                      }}
                    />
                  )}
                />
              </>
            )}
            <Controller
              control={control}
              name={'address_country'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('form.country.label')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    readOnly: true,
                  }}
                  autoComplete="off"
                />
              )}
            />
          </div>
        </InputWrapper>

        <InputWrapper>
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_desc'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyDescription.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                multiline
                rows={4}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                inputProps={{
                  readOnly: isSub || readOnly,
                }}
              />
            )}
          />
        </InputWrapper>

        <div style={{ paddingTop: 20 }}>
          <FormTitle>{t('additionalInformation')}</FormTitle>
          <InputWrapper>
            <MultiSelect
              control={control}
              data={converToMultiLangSelectData(categoryLang, lang)}
              inputLabel={t('form.natureOfBusiness.label')}
              selectLabel={t('info.natureOfBusiness')}
              name={'acc_category'}
              readOnly={isSub || readOnly}
            />
            {watchCategory && watchCategory.includes('Other') && (
              <Controller
                control={control}
                name={'acc_category_other'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('form.natureOfBusiness.other')}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%', marginTop: '15px' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    inputProps={{
                      readOnly: isSub || readOnly,
                    }}
                  />
                )}
              />
            )}
          </InputWrapper>
          {showLegalService && (
            <>
              <InputWrapper>
                <MultiSelect
                  control={control}
                  data={sectorOptions}
                  inputLabel={t('info.lawFirmSectorSpecialty')}
                  selectLabel={t('info.lawFirmSectorSpecialty')}
                  name={'law_firm_sector_specialty'}
                  readOnly={isSub || readOnly}
                />
              </InputWrapper>
              <InputWrapper>
                <MultiSelect
                  control={control}
                  data={areasOptions}
                  inputLabel={t('info.lawFirmPracticeAreas')}
                  selectLabel={t('info.lawFirmPracticeAreas')}
                  name={'practice_areas'}
                  readOnly={isSub || readOnly}
                />
              </InputWrapper>
            </>
          )}
          <InputWrapper>
            <MultiSelect
              control={control}
              data={languages}
              inputLabel={t('form.languages.label')}
              selectLabel={t('info.languages')}
              name={'acc_languages'}
              readOnly={isSub || readOnly}
            />
            {watchAccLanguage && watchAccLanguage.includes('Other') && (
              <Controller
                control={control}
                name={'acc_languages_other'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('form.languages.other')}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    inputProps={{
                      readOnly: isSub || readOnly,
                    }}
                  />
                )}
              />
            )}
          </InputWrapper>

          <InputWrapper>
            <MultiSelect
              control={control}
              data={currencyData}
              inputLabel={t('form.currency.label')}
              selectLabel={t('info.currency')}
              name={'acc_currency'}
              readOnly={isSub || readOnly}
            />
            {watchCurrency && watchCurrency.includes('Other') && (
              <Controller
                control={control}
                name={'acc_currency_other'}
                defaultValue={''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={t('form.currency.other')}
                    variant="standard"
                    value={value}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                    inputProps={{
                      readOnly: isSub || readOnly,
                    }}
                  />
                )}
              />
            )}
          </InputWrapper>
        </div>

        {ButtonGroup({ ...watch() })}
      </FormBox>
    </>
  );
};

export default CompanyInfo;
